import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Kunal Ganglani | Developer', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Welcome to Mi Casa', // e.g: Welcome to my website
};
export const heroData = {
  title: '', // Hello, my name is
  name: 'Kunal', // John
  subtitle: '& Stars inspire my coding spark', // I'm the Unknown Developer.
  cta: '', // Know more
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.png', // put your profile image (recommended aspect radio: square)
  paragraphOne: `Hey there!! I'm Kunal Ganglani, your friendly neighborhood staff software engineer hailing from the vibrant city of Indore, the snacking capital of India. Beyond the realm of coding and automation, I'm an avid enthusiast of diverse interests that add color to my life.
    Anime and gaming are my go-to escapes, where I find inspiration and entertainment in the fantastic worlds created by these mediums. Whether it's the thrill of a challenging game or the emotional depth of a well-crafted anime, I'm always up for a journey into the extraordinary.`,
  paragraphTwo: `Long drives are my therapy sessions, offering a perfect blend of solitude and exploration. There's something magical about the open road and the freedom it brings. It's during these drives that I often find myself pondering over the latest investment strategies or brainstorming ideas for my next coding adventure.
  Speaking of investments, I have a keen interest in the world of finance and believe in the power of strategic investments. It's a thrilling puzzle to decode, much like the challenges I encounter in my coding endeavors.`,
  paragraphThree: `When I'm not immersed in the digital realm, I love discovering life hacks that make everyday tasks a breeze. Efficiency is the key, whether it's optimizing code or streamlining daily routines.
  So, if you're into anime discussions, gaming marathons, contemplating the mysteries of the financial world, or just cruising down the highway, I'm your person! Let's connect and explore the endless possibilities that life, coding, and a good bag of snacks from Indore have to offer.`,
  resume: 'mailto:kunalganglani@gmail.com',
  meetme: 'https://www.calendly.com/kunalganglani',
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'kgtricks.png',
    title: 'KGtricks.com',
    info:
      'Tech Blog where I share the tech tips tricks and hacks. Starting during my college days, it has crossed 600000 followers',
    info2: '',
    url: 'https://www.kgtricks.blogspot.com',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'whatsappweb.png',
    title: 'Whatsapp Web',
    info:
      'Forked version of whatsapp web to show my design and coding skills. This is a Progressive web application',
    info2: '',
    url: 'https://tinyurl.com/kunalwhatsapp',
    repo: 'https://github.com/kunalganglani/whatsappweb', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'cubesort.png',
    title: 'Cubesort',
    info: 'App build on Webpack and ES6 to show magical javascript animations',
    info2: '',
    url: 'https://cubesort.web.app/',
    repo: 'https://github.com/kunalganglani/cubesort', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'kunalganglani@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: 'http://twitter.com/kunalganglani', // your twitter url
    },
    {
      id: nanoid(),
      name: 'codepen',
      url: 'https://codepen.io/kunalganglani', // your codepen url
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/kunalganglani', // your linkedin url
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/kunalganglani', // your github url
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
